<template>
  <div class="search_filter_wrap" style="margin-top: 27px">
    <div class="search_item_wrap">
      <div class="filter_category_box">
        <el-select id="category" v-model="searchOption" class="my_filter select"
                   popper-class="category_select"
                   :popper-append-to-body="false"
        >
          <el-option :value="'ina'" :label="$t('subject')"></el-option>
          <el-option :value="'hash'" :label="$t('hashtag')"></el-option>
          <el-option :value="'orni'" :label="$t('k_creator')"></el-option>
          <el-option :value="'prni'" :label="$t('owner')"></el-option>
          <el-option :value="'cuni'" :label="$t('curator')"></el-option>
        </el-select>
      </div>
      <div class="search_input">
        <el-input :placeholder="$t('st')" v-model="searchInput"
                  @input="setData('searchInput',searchInput)"
                  @keypress.native.enter="setFilterFunc(searchOption,searchInput)"/>
        <button class="search_btn" @click="setFilterFunc(searchOption,searchInput)"><span>{{$t('search')}}</span>
          <img src="@/assets/image/main/search_btn.png"></button>
      </div>
      <div class="end_filter">
        <!--        <button class="filter_btn two" @click="changeCheck('filterEndCheck')"></button>-->
        <el-popover
            popper-class="top_500"
            placement=""
            width="435"
            trigger="click"
            :open-delay="300"
            :offset="0"
            :append-to-body="false"
            :popper-options="{
              boundariesElement: 'body',
              gpuAcceleration: true,
              positionFixed: true,
              preventOverflow: true,
            }">
          <el-button slot="reference" class="filter_btn two"></el-button>
          <div class="popup_tail"></div>
          <div class="popup_bg">
            <div class="popup_inner">
              <div class="inner_top"><span class="title">{{ $t('filter') }}</span></div>
              <div class="inner_middle">
                <div class="sub_title">{{$t('detection')}}{{$t('numberCase')}}</div>
                <el-input v-model="filterPrice[0]" class="el_price_input el_input_num" type="number"
                          @keypress.native.enter="setData('pr',filterPrice)"/>
                <span> {{ $t('count') }}</span>
                <span class="first_input_step">~</span>
                <el-input v-model="filterPrice[1]" class="el_price_input el_input_num" type="number"
                          @keypress.native.enter="setData('pr',filterPrice)"/>
                <span> {{ $t('count') }} </span>
                <div class="sub_title">{{$t('own2')}}{{$t('dateTime')}}</div>
                <div class="price_wrap">
                  <el-date-picker
                      class="range_time"
                      v-model="inputDate"
                      type="datetimerange"
                      range-separator="~"
                      start-placeholder="YYYY-MM-DD HH:MM"
                      end-placeholder="YYYY-MM-DD HH:MM">
                  </el-date-picker>
                </div>
              </div>
            </div>
          </div>
          <div class="wrapper_bottom">
            <button class="">{{$t('apply2')}}</button>
          </div>
        </el-popover>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DetectionFilterDefaultLayout",
  mixins: [],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{
      searchOption: 'ina',
      sortOption: 'i.updated_at',
      searchInput: '',

      filterPrice: [],
      inputDate: [],

    }
  },
  beforeRouterEnter() {},
  created() {
    this.setterFilterDataInData();
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    setterFilterDataInData() {
      let classnames = Object.keys(this.filterData);
      classnames.forEach(classname => {
        let obj = this.filterData[classname];
        let type = filterConfig[obj.key].type;
        if (this.hasOwnProperty(classname)) {
          // this[classname] =
          if (type == 'query') {
            this[classname] = obj.query;
            if (this.hasOwnProperty(classname + 'Option')) {
              this[classname + 'Option'] = obj.key;
            }
          } else if (type === 'between') {
            this[classname] = [obj.start, obj.end];
          } else if (type === 'match') {
            if (isNaN(obj.value)) {
              this[classname] = obj.value;
            } else {
              this[classname] = Number(obj.value);
            }

          } else if (type === 'terms') {
            this[classname] = obj.terms;
          }

        }
      })
    },
    sortChange(data) {
      if (data === 'priceAsc') {
        this.setFilterFunc('obt', 'i_price');
        this.setFilterFunc('obs', 'asc');
      } else if (data === 'priceDesc') {
        this.setFilterFunc('obt', 'i_price');
        this.setFilterFunc('obs', 'desc');
      } else {
        this.setFilterFunc('obt', data);
        this.setFilterFunc('obs', 'desc');
      }
    },
    setFilterFunc(key, value) {
      this.setFilter(key, value);
    },
    setData(key, value) {
      this[key] = value;
    },
    openModal(name) {
      this.$modal.show(name);
    },

  },
  watch: {

  },
}
</script>

<style scoped>

</style>
